<template>
  <div>
    <NavTools />
    <div class="container-fluid">
      <div class="row page-header-banner page-header-banner-image">
        <div class="col-xs-12">
          <center>
            <h1 class="page-title">Junior Feedback</h1>
          </center>
        </div>
      </div>
    </div>

    <div
      v-if="render"
      class="container main-body"
      style="background-color: #fff"
    >
      <div class="row">
        <div class="col-md-10 col-md-offset-1" style="font-size: 125%">

          <p class="text-center">Example Feedback Video 1</p>
          <div class="text-center">
            <video height="auto" style="max-width:480px" playsinline preload="auto" controls>
              <source src="/shield/mercury/file/722949d85b25a6f195fa57e3bce7cf3b/" type="video/mp4">
            </video>
          </div>

          <p class="text-center">Example Feedback Video 2</p>
          <div class="text-center">
            <video height="auto" style="max-width:480px" playsinline preload="auto" controls>
              <source src="/shield/mercury/file/b4423fe1fb8865c14101d60b9130e897/" type="video/mp4">
            </video>
          </div>

          <p>When providing feedback to the students, consider the following items:</p>
          <ul>
            <li>I learned something about the student that was interesting or compelling</li>
            <li>The student connected concepts suggested by the interviewer</li>
            <li>The student gave meaningful reflections on their academic and/or extracurricular experiences</li>
            <li>The student provided specific examples to support their arguments</li>
            <li>The student was comfortable in the interview setting</li>
            <li>The student engaged in conversation easily</li>
            <li>The student was a good self-advocate</li>
            <li>The student responded well to second-tier and follow-up questions</li>
            <br>
            <li>The student was too rehearsed
              <ul>
                <li>If a story sounds too perfect, they are probably reciting from memory.</li>
                <li>If they are resistant to the interview interrupting and asking a question, they are probably reciting from memory</li>
                <li>If they are trying to stretch one answer to fit a question, they’re likely reciting from memory</li>
              </ul>
            </li>
            <li>The student did not share anything beyond the surface level or what we would likely learn on their list of activities</li>         
            <li>The student was resistant to sharing personal thoughts, likes, opinions</li>
            <li>The student kept going back to one particular experience they feel comfortable discussing at length</li>
            <li>The student responded in one word answers (yes, no) without providing context or examples</li>
          </ul>

          <p>When recording your response, please highlight specific examples of what the student does well or where they have room for improvement. You can provide them with specific time stamps or specific questions so they can reference what you mean.</p>
          <p>The goal is to allow them to generally improve, not polish specific answers to questions. The best feedback for them is feedback that applies to other questions that other interviewers will likely provide. For example:</p>

          <ul>
            <li>If they did a good job of answering a question with an example to highlight their response, tell them that.</li>
            <li>If they sounded too rehearsed when they were describing an experience for an activity, tell them that.</li>
            <li>If they don’t share what they think when they are directly asked what they think, tell them that.
              <ul><li>Admissions officers are interested in learning about your thoughts and opinions because it helps them see how you will fit on their university campus.</li></ul>
            </li>
          </ul>

          <p>Overall, be constructive, but supportive. The students are seeking to grow by doing this interview and will value the feedback you are able to provide them. You can also use the text message box to reiterate your constructive response.</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueResource from "vue-resource";
Vue.use(VueResource);

import NavTools from "@/components/NavTools.vue";

export default {
  props: ["staticaddr", "serveraddr", "ameliaaddr", "lang", "lang_label"],

  components: {
    NavTools,
  },

  data() {
    return {
      render: false,
    };
  },

  mounted() {
    var redirecting = require("@/utils/LoginRequired.js");
    if (!redirecting) {
      this.render = true;
    }
  },
};
</script>

<style lang="scss">
.page-header-banner-image {
  background-image: url(/ivgcs/img/chalkboard-black.jpg);
}

.page-header-banner-row {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.page-title {
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 0;
}

.main-body {
  padding-top: 50px;
  padding-bottom: 50px;
}

.slate-docs td {
  font-size: 100%;
}
</style>